body {
  margin: 0;
}

.login {
  background-color: #000;
  color: #fff;
  font-family: "Inter";
  height: 100%;
}

.loginLogo {
  width: 270px;
}

.loginImage {
  margin-bottom: 110px;
}

.loginH2 {
  margin: 0;
}

.loginFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 190px;
}

.loginFlexText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginH2 {
  font-weight: 600;
  font-size: 2rem;
}

.loginBodyText {
  font-weight: 100;
  font-size: 0.9rem;
  margin: 8px;
}

.loginFlexSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
