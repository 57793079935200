body {
  margin: 0;
  font-family: "Inter";
}

#root {
  height: 100%;
}

.Trips {
  display: flex;
  height: 100%;
  padding-right: 30px;
}

.TripsSection {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  width: 100%;
}

.TripsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TripsFilter {
  display: flex;
  justify-content: space-between;
}
