.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16.85px;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 16px;
}

.headerButtons {
  min-width: 280px;
  display: flex;
  justify-content: space-between;
}

.headerButtons button {
  background: #212143;
  color: white;
  padding: 6px 11px;
  border-radius: 4px;
  border: none;
  font-family: "Inter";
}

.headerButtons button:hover {
  cursor: pointer;
}

.headerAddCustomer:hover {
  opacity: 90%;
}

.headerCreateQuote:hover {
  opacity: 90%;
}
