.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(
    --background-color
  ); /* Define the background color in a global CSS file or replace with your specific color */
}

.card {
  width: 100%;
  max-width: 28rem; /* 448px */
  padding: 2rem; /* 32px */
  background: white;
  border-radius: 0.75rem; /* 12px */
  border: 1px solid rgb(245, 240, 240);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.05), 1px 2px 4px 0 rgba(0, 0, 0, 0.05); /* Define card shadow */
  margin: 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem; /* 24px */
}

.icon {
  color: #22c55e; /* Green color */
  width: 3rem; /* 48px */
  height: 3rem; /* 48px */
}

.text-center {
  text-align: center;
}

.heading {
  font-size: 1.5rem; /* 24px */
  font-weight: bold;
}

.subtext {
  color: rgb(82, 81, 81); /* Define muted text color */
  text-align: center;
}

.divider {
  width: 100%;
  border-top: 1px solid rgb(219, 216, 216); /* Define divider color */
  padding-top: 1.5rem; /* 24px */
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* 8px */
}

.flexBetween {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.total {
  font-weight: 800;
}

.linkGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem; /* 16px */
}
.paymentBody {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.linkPrimary {
  display: inline-flex;
  height: 2.5rem; /* 40px */
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem; /* 6px */
  background-color: black; /* Define primary background color */
  padding: 0 2rem; /* 32px */
  font-size: 0.875rem; /* 14px */
  font-weight: 500;
  color: white; /* Define primary foreground color */
  box-shadow: var(--link-shadow); /* Define button shadow */
  transition: background-color 0.2s;
}

.linkPrimary:hover {
  background-color: rgb(65, 63, 63); /* Define primary background hover color */
}

.linkSecondary {
  display: inline-flex;
  height: 2.5rem; /* 40px */
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem; /* 6px */
  border: 1px solid rgb(245, 240, 240); /* Define input border color */
  background-color: var(--background-color); /* Define background color */
  padding: 0 2rem; /* 32px */
  font-size: 0.875rem; /* 14px */
  font-weight: 500;
  color: black;
  font-weight: bold;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.05), 1px 2px 4px 0 rgba(0, 0, 0, 0.05); /* Define button shadow */
  transition: background-color 0.2s;
}

.linkSecondary:hover {
  background-color: rgba(240, 234, 234, 0.603) /* Define accent background color */; /* Define accent foreground color */
}
.linkPrimary,
.linkSecondary {
  text-decoration: none;
}

.linkSecondary:focus-visible,
.linkPrimary:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--ring-color); /* Define ring color */
}

.linkPrimary[disabled],
.LinkSecondary[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
