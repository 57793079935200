.titleOrLogo {
  display: flex;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.non-contract-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NCmodal-body {
  box-shadow: 0px 4px 16px 0px rgba(17, 34, 17, 0.1);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 43px;
}

.divsLabelSignature {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.labelSignature {
  align-items: start;
  flex-wrap: wrap;
  align-items: center;
}

.dateLabel,
.labelSignatureInput {
  max-width: 500px;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0px;
  text-align: center;
  font-size: clamp(2rem, 3vw, 3rem);
  font-family: "Brush Script MT", cursive;
  background: none;
  flex-wrap: wrap;
}

.dateLabel {
  font-size: clamp(20px, 2vw, 25px);
  font-family: "Comic Sans MS", cursive;
}

.labelSignatureInput:focus-visible {
  outline: none;
}

.disclaimerText {
  font-size: 0.75rem;
  margin: 25px 0px;
}

.companyAddress {
  text-align: center;
  font-size: 0.67rem;
  opacity: 50%;
}

.textTitle:nth-child(2) {
  font-size: 20px;
  font-weight: 600;
}

.divTableRow {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}

.firstSection .text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.secondSection {
  margin: 20px 0px;
}

.divTableCellTitle {
  font-size: 1.2rem;
  font-weight: 600;
}

.divTableCell {
  font-weight: 600;
}

#partNowPartLaterBtn,
#payEspeciallyBtn,
#payInFullBtn {
  cursor: pointer;
  margin: 10px 0px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vw;
  min-height: 119px;
  border-radius: 12px;
  border: none;
  flex: 1;
}

.buttonText {
  text-align: left;
}

.depositButton {
  font-weight: 600;
  font-size: 0.9rem;
  font-family: "Inter", sans-serif;
}

.depositText {
  font-size: 0.9rem;
  font-family: "Inter", sans-serif;
}

.selected {
  background-color: #8dd3bb; /* Adjust the background color as needed */
}

.paymentNowLaterButtons {
  box-shadow: 0px 4px 16px 0px rgba(17, 34, 17, 0.1);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 7px;
  margin: 15px 0px;
  flex-wrap: wrap;
}

.moreInfo {
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  margin: 10px 0px;
}

.moreInfo:hover {
  opacity: 95%;
}

.bottomSquare {
  display: flex;
  flex-direction: column;
}

.sendButtons {
  text-align: center;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 7px;
  box-shadow: 0px 4px 16px 0px rgba(17, 34, 17, 0.1);
  box-sizing: border-box;
}

.sendButtons button {
  padding: 15px 0;
  color: white;
  background: #212143;
  border-radius: 6px;
  border: none;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  text-align: center;
  flex: 1;
  font-weight: 600;
}

.sendButtons button:nth-child(1) {
  background: #b70f0f;
}

.sendButtons button:hover {
  background: #8dd3bb;
}

.Instructions {
  margin: 7px 0px;
  height: 16px;
  color: darkcyan;
  font-weight: 600;
}

.invoice-footer {
  display: flex;
}

.invoiceWholeFooter {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.invoice-header {
  width: 100%;
}

.invoice-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.invoice-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.signature {
  min-height: 155px;
  background: #ededed;
  padding: 43px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.calendarButton {
  background: #c0c0c1;
  border-radius: 6px;
  border: none;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  font-size: x-large;
  margin-left: 1rem;
}

.calendarButton:hover {
  background: #8dd3bb;
}

.inputTime {
  display: flex;
  width: 40%;
}

.inputs {
  display: flex;
  width: 100%;
  gap: 15px;
}

.row2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 1rem 0; /* Adjust margin as needed */
}

.row2 label {
  flex: 1 1 45%; /* Allow two labels per row */
  display: flex;
  flex-direction: column;
}

.row2 input {
  width: 100%; /* Ensure inputs fill the available width */
}

.bigSection2 {
  justify-content: center;
  align-items: center;
}

.bigSection2 h2 {
  font-weight: 600;
  font-size: 1.5rem;
  padding: 2rem 0;
  align-items: center;
  text-align: center;
}

.hiddens {
  visibility: hidden;
}

.notes {
  display: flex;
  width: auto;
  margin: 1rem 2rem;
}

.textarea {
  width: 100%;
  height: 100px;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  text-align: start;
  padding: 10px;
  font-size: medium;
}

@media (max-width: 950px) {
  .NCmodal-body{
    padding: 0;
  }

  .bigSection2{
    padding: 0;
  }

  .row2{
    margin: 2rem 0;
  }

  .row2 label{
    width: 100%;
    margin:  0 1rem;
  }

  .LeftSide {
    max-width: 100%;
  }

  .divsLabelSignature {
    justify-content: center;
    gap: 10px;
  }
  
  .depositText {
    display: none;
  }
}

@media (max-width: 550px) {
  .sendButtons {
    flex-direction: column;
    gap: 10px;
  }

  .sendButtons button {
    width: 100%;
    padding: 15px 0;
  }

  .circleDot {
    display: none;
  }

  .buttonText {
    text-align: center;
  }
}
