/* Add these styles to your existing CSS file or create a new one */
.invoice-section {
  display: grid;
  grid-gap: 10px; /* Adjust the gap between grid items */
  /* Optional: Add styling for better readability */
  padding: 10px;
  border-radius: 5px;
  margin: 55px 0px 8px 0px;
}

.invoice-header,
.invoice-row,
.invoice-footer {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 1fr 1fr 0.3fr; /* Same as the main grid */
  font-weight: bold; /* Make text bold for better visibility */
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.invoice-header {
  grid-template-columns: 2fr 2fr 1.5fr 1fr 1fr;
}

.invoice-footer input {
  width: 80px; /* Adjust input width for tax rate input */
}

/* Optional: Style the grand total row differently */
.invoice-footer:last-child {
  font-size: 1.2em;
  font-weight: bold;
}

.delete-row-button {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: red;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  background: none;
}

.invoice-header {
  grid-gap: 6px;
}

.invoice-row {
  text-align: center;
  align-items: center;
  grid-gap: 6px;
}

.invoice-footer {
  text-align: center;
}

.invoice-footer div {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .invoice-footer div input {
  margin-top: 0;
} */

.add-row-button {
  max-width: 179px;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #c2c2c2;
  padding: 8px;
  background: forestgreen;
  color: white;
  cursor: pointer;
  margin-top: 20px;
}
